input {
	padding: 10px;
	height: auto;
	color: $black;
}

.radio-inline {
	font-size: 14px;
}
//form
.form-control {
	padding: 3px 5px;
	height: 40px;
	display: block;
	color: $black;
	vertical-align: middle;
	border: 1px solid $beige;
	box-shadow: none;
	@include box-shadow(none);
	&.grey {
		background: $base-box-bg;
	}
}

.checkbox {
	font-size: 14px;
}
