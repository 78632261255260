.product-fancybox-close {
		display: none;
	}



.product.content_only {
	padding: 30px;
	> div{
		border: 1px solid $black;
	}

	.product-quickview-close-block {
		text-align: center;
		.product-quickview-close-ph {
			display: inline-block;
			position: relative;
		}
		.product-quickview-close {
			display: inline-block;
			position: absolute;
			top: -23px;
			font-size: 40px;
			color: #2b2e2f;
			z-index: 2;
			width: 100px;
			background-color: $white;
			left: -50px;
			cursor: pointer;
			&:hover {
				color: $link-hover-color;
			}
			&:after {
				content: "\f2d7";
				font-family: $font-icon;
			}
		}
	}
}

.product-title {
	font-size: 40px;
	margin-bottom: 40px;
	font-weight: 400;
}

.product-info {
	.product-info--header {
		border-bottom: 1px solid $border-gray;
		letter-spacing: 1px;
		.product-info--tablink {
			color: $black;
			opacity: 0.5;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 10px;
			margin-right: 20px;

			display: inline-block;
			cursor: pointer;
			&.active, &:hover {
				opacity: 1;
			}
		}
	}

	.product-info--tabcontent {
		display: none;
		font-size: 16px;
		&.active {
			display: block;
		}
	}
}

.product-origine--header {
	border-bottom: 1px solid $border-gray;
	margin-top: 30px;
	letter-spacing: 1px;

	.product-origine--tablink {
			color: $black;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 10px;
			margin-right: 20px;
			display: inline-block;

		}
}
.product-origine  {
	font-size: 16px;
}
.product-reference {
	font-size: 13px;
	font-style: normal;
	color: $header-gray;
}
.product-caracteristics {
	margin-top: 30px;
	li {
		display: inline-block;
		width: 50px;
		margin-right: 10px;
	}
}

.product-contact {
	margin-top: 30px;
}

#pQuantityAvailable {
	font-weight: normal;
	font-size: 12px;
}



/*** ZOOM ***/
#view_full_size {
	display: block;
	overflow: hidden;
}

