.btn-julie {
  width: 200px;
  height: 40px;
  padding:20px;
  border: none;

  position: relative;
  transition: all 0.5s;
  cursor:pointer;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;

  font-size: 14px;
  letter-spacing:1px;

  font-weight:700;



  i.icon {
    display: inline-block;
    width: auto;
    height: auto;
    line-height: auto;
    vertical-align: baseline;
    margin: 0px;

    &.ion-chevron-right {
      margin-left: 10px;
    }
    &.ion-chevron-left {
      margin-right: 10px;
    }
  }
}

.btn-julie::before {
  content: "";
  width: 0px;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: all 0.6s;

}

.btn-julie:hover::before {
  width: 200px;
  height: 40px;
}

.btn-julie span {
  color: $white;
  width: 200px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 40px;
}
.btn-julie:hover span {
  color: #fff;
}



.btn-julie1 {
  background-color: $header-gray; /*light*/
  &::before {
    background-color: $gray-hover;  /*dark*/
  }
}

.btn-julie2    {
  background-color: $header-gray; /*light*/
  &::before {
    background-color: $beige;  /*dark*/
  }
}


.btn-julie_text-small {
  font-size: 12px;
  letter-spacing:1px;
  text-transform:uppercase;
  position: relative;
  font-weight:700;
}
.btn-julie.btn-julie-connexion {
  width: 300px;
  span {
    width: 300px;
  }
  &:hover::before {
    width: 300px;
    height: 40px;  
  }
}

.btn-julie.btn-julie-continue {
  width: 250px;
  span {
    width: 250px;
  }
  .icon {
    position: absolute;
    top: 12px;
    left: 10px;
  }
  
  &:hover::before {
    width: 250px;
    height: 40px;  
  }
}
.btn-julie.btn-julie-order {
  width: 250px;
  span {
    width: 250px;
  }
  .icon {
    position: absolute;
    top: 12px;
    right: 10px;
  }
  &:hover::before {
    width: 250px;
    height: 40px;  
  }
}

.btn-julie.btn-julie-cart {
  width: 300px;
  .icon {
    font-size: 28px;
    position: absolute;
    color: #ffffff;
    top: 4px;
    right: 30px;
  }
  span {
    width: 300px;
  }
  &:hover::before {
    width: 300px;
    height: 40px;  
  }
}

.btn-julie.btn-julie-small {
  width: 50px;
  .icon {
    font-size: 28px;
    position: absolute;
    color: #ffffff;
    top: 4px;
    right: 30px;
  }
  span {
    width: 50px;
  }
  &:hover::before {
    width: 50px;
    height: 40px;  
  }
}

.pagenotfound .btn-julie-small {
  float: right;
}

.contact-container {
  div.uploader span.action {
    background: $header-gray;
    text-shadow: none;
    cursor: pointer;
    transition: all 0.3s;
    display: inline-block;

    &:hover {
      background-color: $gray-hover; 
    }
}
}