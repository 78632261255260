/*** HEADER ***/
  .header-container {
    border-bottom: 1px solid $gray;
  }
  
.header {
  background: white;
  font-size: 1rem;

  .header-logo {
      padding-bottom: 1em;
      border-bottom: 1px solid #d6d4d2;
    }

  .logo {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  a {
    color: $black;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
  }
  .header-nav {
    border-bottom: 1px solid $gray;
    max-height: 50px;
    margin-bottom: 1em;

    .connexion-block {
      height: 100%;
      font-size: 14px;

      a {
        padding: 15px 5px 15px 5px;
        display: inline-block;
        &:hover {
          color: $beige;
        }
      }
    }
    .connexion-menu {
      height: 100%;
      margin: 0px;

/*       font-weight: bold; */
      li {
        height: 100%;
        display: inline-block;

        &:after {
          content: '|';
        }
        &:last-child:after {
          content: '';
        }
        a {
          display: inline-block;
          
          border-bottom: 2px solid transparent;
          &:hover {
            border-bottom: 2px solid $beige;
            color: $beige;
          }
        }
      }
    }
    #menu-icon {
      vertical-align: middle;
      cursor: pointer;
      margin-left: 1rem;
      .material-icons {
        line-height: 50px;
      }
    }
    .right-nav {
      text-align: right;

    }
    .currency-selector {
      margin-top: 0.9375rem;
      margin-left: 0.9375rem;
      white-space: nowrap;
    }
    .user-info {
      margin-left: 2.5rem;
      margin-top: 0.9375rem;
      text-align: right;
      white-space: nowrap;
      .account {
        margin-left: 5px;
      }
    }
    .language-selector {
      margin-top: 0.9375rem;
      white-space: nowrap;
    }
    .cart-preview {
      .shopping-cart {
        vertical-align: middle;
        color: $gray;
      }
      .body {
        display: none;
      }
    }


  }
  .header-top {
    .header-logo {
      text-align: center;
      padding-bottom: 1em;
      border-bottom: 1px solid $gray;
    }
    > .container {
      position: relative;
    }
    .menu {
      > ul {
        margin: 0px;
        height: 100%;
        display: inline-block;
        text-align: center;
        > li {
          float: left;
          height: 100%;
          a {
            height: 100%;
            display: inline-block;
            padding: 15px 10px;
            &:hover {
              background-color: $beige;
              color: $white;
            }
          }
        }
      }
    }
    .mobile-menu-icon {
      font-size: 2em;
    }

    .search-widget {
      input {
        display: inline-block;
        margin: 10px 10px;
        padding: 5px;
        float: left;
        outline: none;
      }
      button {
        background: none !important;
        color: inherit;
        border: none;
        padding: 0! important;
        font: inherit;
        cursor: pointer;
        outline: none !important;
        position: relative;
        float:left;

        .icon {
          font-size: 2em;
          position: absolute;
          top: 8px;
          &:hover {
            color: $beige;
          }

        }
      }
    }
    .position-static {
      position: static;
    }
  }
  .top-menu-link {
    margin-left: 10px;
  }

  .mobile-menu {
    text-align: center;
    font-size: 1.25em;
    position: relative;
    .top-menu {
      position: absolute;
      height: 100vh;
      background-color: $beige;
      width: 100vw;
    }
    a {
      display: inline-block;
      padding: 5px;
      color: $white;
    }

  }

}
