.home-collection-container {
    padding: 60px 0px;
    &:nth-child(even) {
      background-color: $gray-lighter;
    }
    &:nth-child(odd) {
      background-color: $white;
    }
 }
.category-cms-content {
  margin-top: 20px;
}

.collection--title {
  font-size: 2.66em;
  float: left;
  font-weight: 400;
}
.collection--description {
  float: left;
  clear: both;
  p {
    text-transform: uppercase;
    margin: 0px;
    color: $text-gray;  
  }
}
.collection--link {
  float: right;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  margin-top: 42px;
  &:hover {
    color: $beige;
  }
}

.collection-sub--link {
  text-transform: uppercase;
  margin-bottom: 1em;
  overflow: hidden;
  display: block;
  img {
    -webkit-transition: opacity 1s, -webkit-transform 2s;
    transition: opacity 1s, transform 2s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  &:hover {
    img {
      opacity: 0.8;
      -webkit-transform: scale3d(1.1, 1.1, 1);
      transform: scale3d(1.1, 1.1, 1);
    }
  }

}


.collection-header {
  position: relative;
  padding: 20px 40px;
  background-color: $header-gray;
  color: $white;
  margin-top: -100px;

  .collection-header--title {
    color: $white;
    font-size: 48px;
    font-weight: $title-font-weight;
  }
  .collection-header--description {
    font-weight: 300;
    font-size: 14px;
  }
  .collection-header--specifications {
    font-size: 14px;
    li {
      display: inline-block;
      font-weight: 300;
      &:not(:last-child):after {
        content: '|';
        margin: 0px 10px;
      }
      a {
        color: $white;
        &:hover {
          color: $gray-hover;
          //text-decoration: underline;
        }
      }
    }
  }
  .page-share {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 26px;
    color: $white;
    text-align: center;
    i.icon {
      width: auto;
      height: auto;
      margin-left: 0px;
    }
    &.active {
      color: $text-color;
      background-color: $white;
      @include box-shadow(0px 0px 5px 0px rgba(0,0,0,0.30));
    }
    

    .page-share--title {
      padding: 10px 20px;
      cursor: pointer;
      &:hover {
        color: $gray-hover;
      }
    }
    .page-share--list {
      display: none;
      li {
        font-size: 22px;
        a {
          display: block;
          padding: 10px 20px;
          i.icon {
            width: auto;
            height: auto;
            margin-left: 0px;
          }
        }
      }

    }
  }
}

.collection-content {
  clear: both;
}

.collection-sub {
  margin-top: 2em;
}
.collection-sub--title {
  font-family: $base-font;
  font-weight: 600;
  text-transform: uppercase;
  float: left;
  margin: 0px;
  font-size: 16px;
}

.collection-sub--description {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  clear: both;
  p {
    margin: 0px;
  }
}
.collection-sub--price {
  float: right;
  color: $beige;
  font-weight: 600;
}




/********* 
PRODUCT PART
************/
.product-block {
  background-color: $light-gray;

  margin-bottom: 20px;
  @include flexbox();
  @media (max-width: $screen-lg) {
    @include flex-direction(column);
  }
  
  @include justify-content(space-between);
/*   @include align-items(center); */

  .product-left {
    @include flexbox();
    @include flex(1);
    padding: 20px;
    border-right: 1px solid $white;
    @media (max-width: $screen-lg) {

      border-bottom: 1px solid $white;  
      border-right: none;
    }
    .product-image {
      float: left;
    }
    .product-info {
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(center);
      padding: 10px;
      .product-name {
        font-size: 15px;
        line-height: 23px;
        color: #3a3939;
        letter-spacing: 0.7px;
        margin-bottom: 0;
      }
      .quick-view {
         text-decoration: underline;
         font-size: 13px;
      }

    }
  }
  .product-right {
    @include flex(2);
    padding-right: 10px;
    form {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);
      @include justify-content(space-evenly);
      height: 100%;
      @media (max-width: $screen-lg) {
        @include flex-flow(row wrap);
      }
      .product-attributes-block {
        padding: 20px;
        border-right: 1px solid $white;  
        height: 100%;
        @include flexbox();
        @include align-items(center);
        @media (max-width: $screen-lg) {
          width: 100%;
          @include justify-content(center);
          border-bottom: 1px solid $white;  
          border-right: none;
        }

        .nice-select {
          width: 150px;
        }

      }
      .product-quantity-block {
        padding: 20px;
        border-right: 1px solid $white;  
        color: $black;
        text-align: center;
        font-size: 16px;
        height: 100%;
        @include flexbox();
        @include align-items(center);
        @media (max-width: $screen-lg) {
          width: 100%;
          @include justify-content(center);
          border-bottom: 1px solid $white;  
          border-right: none;
        }
        .product-quantity-selector {
          border: 1px solid $black;
          @media (max-width: $screen-lg) {
            width: 70%;
          }
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
        }
        .increase_quantity {
          display: block;
          padding: 10px;    
          border-left: 1px solid $black;
          cursor: pointer;
          @media (max-width: $screen-lg) {
            border: none;
            margin: 0px 20px;
          }
          &:hover {
            background-color: $white;
          }
        }
        .decrease_quantity {
          display: block;
          padding: 10px;    
          border-right: 1px solid $black;
          cursor: pointer;
          @media (max-width: $screen-lg) {
            border: none;
            margin: 0px 20px;
          }
          &:hover {
            background-color: $white;
          }
        }

        .qty {
          border: none;
          background-color: transparent;
          text-align: center;
          width: 50px;
          @media (max-width: $screen-lg) {
            width: 100%;
          }
        }
      }

      .product-price-block {
        text-align: right;
        position: relative;
        margin: 0px 20px;
        color: $black;
        @media (max-width: $screen-lg) {
          margin: 50px 0px;
        }
        .product-price--label {
          display: block;
          position: absolute;
          font-size: 12px;
          top: -30px;
          right: 0px;

        }
        .product-price--value {
          display: block;
          font-family: $title-font;
          font-size: 30px;
          
        }
      }

      .product-add-block {
        @media (max-width: $screen-lg) {
          margin: 50px 0px;
        }

        .ct_submit {
          span {
            left: -20px;
          }
          .icon {
            font-size: 28px;
            position: absolute;
            color: $white;
            top: 4px;
            right: 30px;
          }
        }
      }
    }
  }

}

.collection-guidelinks {
  > div {
    float: left;
    margin-left: 1px;
    margin-top: 1px;
  }
}