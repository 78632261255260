// BREADCRUMB
.breadcrumb {
	display: inline-block;
	position: relative;
	background: none;
	font-size: 13px;
	padding: 15px 40px;
	.navigation-pipe {
		margin: 0px 5px;
		opacity: 0.5;
 	}
	a {
		display: inline-block;
		position: relative;
		color: inherit;
		opacity: 0.5;
		&:hover {
			opacity: 1;
			text-decoration: none;
		}
	}
}