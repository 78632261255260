.account-container {
	margin-top: 80px;
}

.address-container {
	margin-top: 80px;

	.box {
		background: none;
		border: none;
	}
}


.addresses-container {
	margin-top: 80px;

	.address_update {
		a .icon {
			margin-left: 10px;
		}
	}
}

.history-container {
	margin-top: 80px;
}


.slip-container {
	margin-top: 80px;
}

.identity-container {
	margin-top: 80px;
	.box {
		background: none;
		border: none;
	}
}