.address_update {
	i {
		margin-left: 10px;
	}
}

.address_add {
	i {
		margin-left: 10px;
	}	
}

.cart-cgv-ph {
	padding: 20px;
    background: $white;
    height: 300px;
    overflow-y: scroll;
}

#carrier_area {
	@include container-fixed();
	@media (min-width: $screen-tablet) {
	    max-width: $container-tablet;
	}
	@media (min-width: $screen-desktop) {
    	max-width: $container-desktop;
  	}
	@media (min-width: $screen-lg-desktop) {
		max-width: $container-lg-desktop;
	}
}