.page-banner {
  height: 400px;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  align-content: middle;
  background-image: url(../img/banner_default.png);
  background-size: contain;
  background-repeat: repeat;
  h1 {
    font-size: 3em;
    font-weight: 300;
  }
}


/**********
HOME
***********/
.home-collections-menu {
	padding: 20px;
	border-bottom: 1px solid $border-gray;
	text-align: center;
	ul {
		margin: 0px;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 14px;
		li {
			display: inline-block;
			&:not(:last-child):after {
				content: '|';
				color: $header-gray;
				margin: 0px 15px;
			}
		}
	}
}
.page-home {
/*   padding-top: 2em; */
}


/*****
CMS
****/
.cms-container {
	margin-top: 80px;
}

.cms.content_only {
	padding: 30px;


	.cms-quickview-close-block {
		text-align: center;
		.cms-quickview-close-ph {
			display: inline-block;
			position: relative;
		}
		.cms-quickview-close {
			display: inline-block;
			position: absolute;
			top: -5px;
			font-size: 40px;
			color: #2b2e2f;
			z-index: 2;
			width: 100px;
			background-color: $white;
			left: -50px;
			cursor: pointer;
			&:hover {
				color: $link-hover-color;
			}
			&:after {
				content: "\f2d7";
				font-family: $font-icon;
			}
		}
	}

	.rte {
		border: 1px solid $black;
		padding: 20px;
	}
}

/** VISUAL COMPOSER **/

.wpb_text_column {
	font-size: 16px;
	line-height: 25px;

}

