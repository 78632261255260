.connexion-container {
	margin-top: 80px;
}
.form-creation-block {
	border-right: 1px solid $beige;
}
.box.create-account_form {
	padding: 30px;
	border: none;
	background-color: transparent;	
	text-align: center;

	h3 {
		font-family: $title-font;
		border: none;
		font-size: 32px;
	}
	input {
		text-align: center;
		width: 300px;
		display: inline-block;
	}
	.alert {
		margin-top: 20px;
	}
}

.box.login_form {
	padding: 30px;
	border: none;
	background-color: transparent;	
	text-align: center;

	h3 {
		font-family: $title-font;
		border: none;
		font-size: 32px;
	}
	input {
		text-align: center;
		width: 300px;
		display: inline-block;
	}

	.login_form-forgottenlink {
		font-size: 12px;
		width: 300px;
		margin-left: auto;
		margin-right: auto;
		text-align: left;
		display: inline-block;
	}
}


.form-new-account-block {
	.connexion-container {
		margin: 0px;
	}	
}

.box.account-creation_form {
	padding: 30px;
	border: none;
	border-right: 1px solid $beige;
	
	@media (max-width: $screen-sm) {
		border: none;
	}
	background-color: transparent;	


	h3 {
		font-family: $title-font;
		border: none;
		font-size: 32px;
		text-align: center;
	}



}

.account-creation_form {
	.form-group.password {
		input {
			float: left;
		}
		.form_info {
			font-size: 12px;
			margin-top: 20px;
			margin-left: 10px;
			display: inline-block;

		}
	}
	.submit {
		text-align: center;
	}
}

.alert {
	font-weight: normal;
	padding: 0px;
	font-size: 14px;

	ul, ol {
		padding-left: 40px;
		margin: 0px;
		li {
			list-style-type: none;
			font-weight: normal;
		}
	}
	.lnk {
		display: none;
	}
	&.alert-success {
		&:before {
			font-family: $font-icon;
			content: "\f2d7";
			font-size: 20px;
			vertical-align: -2px;
			padding-right: 7px;
		}
	}
	&.alert-danger {
		&:before {
			font-family: $font-icon;
			content: "\f2d7";
			font-size: 20px;
			vertical-align: -2px;
			padding-right: 7px;
			padding-left: 20px;
			float: left;
		}
	}
}

