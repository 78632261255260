@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-ExtraLight.ttf') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-ExtraLightItalic.ttf') format('woff2');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Light.ttf') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-LightItalic.ttf') format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Italic.ttf') format('woff2');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Medium.ttf') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-MediumItalic.ttf') format('woff2');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-SemiBold.ttf') format('woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-SemiBoldItalic.ttf') format('woff2');
    font-weight: 700;
    font-style: italic;
}



/*@font-face {
    font-family: 'butlerblack';
    src: url('butler_black-webfont.woff2') format('woff2'),
         url('butler_black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;}
@font-face {
    font-family: 'butlerbold';
    src: url('butler_bold-webfont.woff2') format('woff2'),
         url('butler_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;}
@font-face {
    font-family: 'butlerextrabold';
    src: url('butler_extrabold-webfont.woff2') format('woff2'),
         url('butler_extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;}*/
@font-face {
    font-family: 'Butler';
    src: url('../fonts/butler_regular-webfont.woff2') format('woff2'),
         url('../fonts/butler_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url('../fonts/butler_light-webfont.woff2') format('woff2'),
         url('../fonts/butler_light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
/*@font-face {
    font-family: 'butlermedium';
    src: url('../fonts/butler_medium-webfont.woff2') format('woff2'),
         url('../fonts/butler_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;}

@font-face {
    font-family: 'butlerultralight';
    src: url('../fonts/butler_ultra_light-webfont.woff2') format('woff2'),
         url('../fonts/butler_ultra_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;}*/